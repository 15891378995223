<template>
  <div ref="root">
    <diva-card>
      <div class="row">
        <div class="col"><h1>{{getTrans('extra.account_type_model')}}</h1></div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
          <base-input
            v-model="model.name"
            :placeholder="getTrans('messages.name')"
            type="name"
            :label="getTrans('messages.model_name_input')"
            :error="getError('name')"
          ></base-input>
        </div>
        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
          <user-avatar :user="{id:0}" @update="avatarUpdated" :size="'xl'" ref="avatar"></user-avatar>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
          <base-input :label="getTrans('messages.country')" :error="getError('country_id')">
          <country-select
            :countries="getCountries"
            v-model="model.country"
          ></country-select>
          </base-input>
        </div>
        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
          <base-input
            v-model="model.city"
            :placeholder="getTrans('messages.city')"
            type="name"
            :label="getTrans('messages.city')"
            :error="getError('city')"
          ></base-input>
        </div>
      </div>
      <el-divider />
      <div class="row">
        <div class="col-12 mb-2"><span class="h3">{{getTrans('messages.email_subtitle')}}:</span></div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
          <base-input
            v-model="model.email"
            :placeholder="getTrans('messages.email')"
            type="name"
            :label="getTrans('messages.email')"
            :error="getError('email')"
          ></base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
          <base-input
            v-model="model.password"
            placeholder="**********"
            type="password"
            :label="getTrans('messages.password')"
            :error="getError('password')"
          ></base-input>
        </div>
        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
          <base-input
            v-model="model.confirm_password"
            placeholder="**********"
            type="password"
            :label="getTrans('messages.confirm_password')"
            :error="getError('confirm_password')"
          ></base-input>
        </div>
      </div>
    </diva-card>
    <diva-card>
      <div class="row">
        <div class="col-6">
          <base-input :label="getTrans('messages.add_camsite_profiles_to_account')"><span></span></base-input>
        </div>
        <div class="col-6">
          <base-input :label="getTransChoise('messages.profiles_count', model.performer ? 1:0)"><div></div></base-input>
        </div>
      </div>
      <div class="search-container">
        <performer-search @updated="selectedProfilesUpdated" :select_limit="1"></performer-search>
      </div>
    </diva-card>
    <diva-card>
      <div class="row m-2">
        <div class="col-12 text-center">
          <base-button plain type="info" @click="cancel">{{getTrans('messages.cancel')}}</base-button>
          <base-button type="primary" :loading="loading" @click="save">{{getTrans('messages.save')}}</base-button>
        </div>
      </div>
    </diva-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Errors from "@/mixins/Errors";
import PerformerSearch from '@/views/Profiles/AddProfiles/PerformerSearch.vue';
import UserAvatar from "@/views/Settings/components/UserAvatar.vue"
import CountrySelect from "@/components/Diva/Inputs/CountrySelect";
import {ElDivider} from 'element-plus'

export default {
  name: 'create-model',
  mixins: [Errors],
  components: {
    PerformerSearch,
    CountrySelect,
    UserAvatar,
    ElDivider,
  },
  data() {
    return {
      selectedPlatform: '',
      search: '',
      model: {
        name: '',
        password: '',
        confirm_password: '',
        country: '',
        city: '',
        email:'',
        performers: [],
        performer: '',
      },
      hasSearched: false,
      avatar: null,
    }
  },
  computed: {
    ...mapGetters("Model", {errors: 'getErrors', loading: 'loading'}),
    ...mapGetters("Country", ['getCountries']),
  },
  methods: {
    save() {
      let data = {
          name: this.model.name,
          city: this.model.city,
      };
      if (this.model.email) data.email = this.model.email;
      if (this.model.country.id) data.country_id = this.model.country.id;
      if (this.password) data.password = this.model.password;
      if (this.password_confirmation) data.password_confirmation = this.model.password_confirmation;
      if (this.avatar) data.profile_pic = this.avatar;
      if (this.model.performer) data.performer_id = this.model.performer;
      this.$store.dispatch("Model/create", data)
      .then(() => {
        this.$toast.success(this.getTrans("messages.account")+ ': '+this.getTrans('messages.created'));
        this.$router.push({name: 'models'});
      }).catch(() => {
        this.$toast.error(this.getTrans('messages.save_failed'));
        this.scrollToFirstError();
      });
    },
    cancel() {
      this.$router.back();
    },
    selectedProfilesUpdated(performers) {
      this.model.performer = performers.map(performer => performer.id);
    },
    avatarUpdated(avatar) {
      this.avatar = avatar.profile_pic;
    },
  },
  mounted() {
    this.$store.dispatch("Country/get")
  }
}
</script>
<style scoped>
.search-container:deep(.result-container) {
  max-height: 600px;
  overflow-y: auto;
}
.large-collapse-item:deep(.el-collapse-item__header) {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #11cdef;
  color:white;
  border-radius: 10px;
  border-width: 3px;
}
.large-collapse-item:deep(.el-collapse-item__header span) {
  color: white;
}
.large-collapse-item:deep(.el-collapse-item) {
  background-color: #ccfbff;
  border-radius: 10px;
}
.large-collapse-item:deep(.el-collapse-item > div) {
  background-color: none;
}
</style>
